import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Grid, Theme, Box, Divider } from "@mui/material";
import {
  ArrowBackIosOutlined,
  UndoOutlined,
  RedoOutlined,
  FiberManualRecordOutlined,
  PlayArrowOutlined,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import StyledButton from "../StyledButton";
import StyledIconButton from "../StyledIconButton";
import {
  UpdateClient,
  NoClient,
  NoConnection,
  NoRecorder,
  Hint,
} from "../Modals";
import { useRootStoreContext } from "../../hooks";
import {
  formatDuration,
  checkClientVersion,
  runTest,
  sendReports,
} from "../../models/utils";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    backgroundColor: theme.palette.white.main,
  },
  headerContainer: {
    height: 56,
    padding: theme.spacing(0, 2),
  },
  undo: {
    marginRight: theme.spacing(1),
  },
  actionItem: {
    marginRight: theme.spacing(2),
  },
  divider: {
    backgroundColor: theme.palette.catskillWhite.main,
    margin: theme.spacing(0, 4),
  },
  hintArea: {
    zIndex: 1200,
    backgroundColor: theme.palette.white.main,
    margin: -16,
    padding: 8,
  },
}));

declare global {
  interface Window {
    TA_ON_RECORDER_CLOSED: () => void;
    TA_ON_RELOAD_TEST: () => void;
    TA_ON_VERION_UPDATE_FINISH: (isSuccessfull: boolean) => void;
    TA_ON_RECORD_STEP: () => void;
  }
}

const TestHeader = observer(({ useSharedSteps = false }) => {
  const { t } = useTranslation(["test_steps", "notifications", "common"]);
  const { suiteId = "", testId = "" } = useParams<{
    suiteId: string;
    testId: string;
  }>();
  const classes = useStyles();
  const history = useHistory();
  const {
    projects,
    tests,
    testFilters,
    testSteps,
    users,
    suites,
    elements,
    // environments,
    variables,
    notifications,
    settings: { downloadClientUrl },
    users: {
      profile: { isHintShown, updateHints, skipAllHints },
    },
  } = useRootStoreContext();
  const test = tests.getById(testId);
  const projectId = test!?.ProjectId;
  // TODO: Show modal window if updateClientVersion=true
  const [updateClientVersion, setUpdateClientVersion] = useState("ignore");
  const [noClient, setNoClient] = useState(false);
  const [noRecorder, setNoRecorder] = useState(false);
  const [noConnection, setNoConnection] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [recorderExtId, setRecorderExtId] = useState("");

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const backTestId = searchParams.get("backTestId") || "";
  const backTest = tests.getById(backTestId);

  const [anchorElHint, setAnchorElHint] = useState<HTMLDivElement | null>(null);
  const hintRef = useRef<HTMLDivElement>(null);

  const pageHints = [
    {
      id: "record_test",
      action: () => updateHints("record_test"),
    },
    {
      id: "install_client",
      action: () => {
        if (downloadClientUrl) window.open(downloadClientUrl, "_blank");
        updateHints("install_client");
      },
    },
  ];

  const showHint = (hint: string) => {
    if (hint === "record_test") return !isHintShown("record_test");
    return isHintShown("record_test") && !isHintShown(hint);
  };

  const skipPageHints = () => updateHints(pageHints.map((hint) => hint.id));

  const hintAnchorClass = clsx({
    [classes.hintArea]: pageHints.some((hint) => !isHintShown(hint.id)),
  });

  useEffect(() => setAnchorElHint(hintRef.current), []);

  const reloadTest = () => {
    if (projectId && suiteId && testId) {
      testSteps.load({
        projectId,
        suiteId,
        testId,
      });
      suites.loadOneSuite(projectId, suiteId);
      test.load();
      elements.load({ projectId });
      variables.load({ projectId });
    }
  };

  const anyRecordRunError = noClient || noRecorder || noConnection;

  const noClientConditions = () =>
    !recorderExtId ||
    typeof chrome === "undefined" ||
    !chrome ||
    !chrome.runtime;

  const handleCloseRecorder = () => {
    reloadTest();
  };

  const checkAPICalls = () => {
    if (!!users.profile.lastApiCallAt && updateClientVersion !== "no_response")
      return;

    setNoClient(true);
    return;
  };

  const recordStepAction = async () => {
    const response = await chrome.runtime.sendMessage(recorderExtId, {
      action: "getRecorderActions",
    });

    console.log(response);

    const url = `${process.env.REACT_APP_API_URL}/projects/${projectId}/suites/${suiteId}/tests/${testId}/steps/record`;
    const resp = await fetch(url, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(response),
    });

    const resJSON = await resp.json();
    console.log("find element: ", resJSON);

    chrome.runtime.sendMessage(recorderExtId, {
      action: "sendStepActionResponse",
      response: resJSON,
    });

    reloadTest();
  };

  useEffect(() => {
    window.TA_ON_RECORDER_CLOSED = handleCloseRecorder;
    window.TA_ON_RELOAD_TEST = reloadTest;
    window.TA_ON_RECORD_STEP = recordStepAction;

    checkAPICalls();
  });

  useEffect(() => {
    const extId =
      users.profile.recorderExtId || process.env.REACT_APP_TA_RECORDER;
    if (extId) setRecorderExtId(extId);
  }, [recorderExtId, updateClientVersion, users.profile.recorderExtId]);

  const handleRecord = async () => {
    skipPageHints();
    await test.load();
    if (noClientConditions()) {
      setNoRecorder(true);
      return;
    }

    const invalidClientVersion =
      (await checkVersion(recorderExtId)) !== "ignore";
    if (anyRecordRunError || invalidClientVersion)
      return setIsErrorModalOpen(true);

    if (
      typeof chrome !== "undefined" &&
      chrome &&
      chrome.runtime &&
      test &&
      recorderExtId &&
      projectId
    ) {
      const currentProject = projects.getById(projectId);
      const currentSuite = suites.getById(suiteId);

      chrome.runtime.sendMessage(
        recorderExtId,
        {
          action: "openBrowser",
          parameters: {
            url: test.baseUrl,
            props: {
              projectName: currentProject!?.name,
              projectId,
              suiteId,
              testId,
              testName: test.name,
              suiteName: currentSuite!?.name,
              isUrl: !!test.baseUrl,
            },
          },
        },
        {},
        (response) => {
          if (response && response.isDisconnected) setNoConnection(true);
        },
      );
    } else {
      setNoRecorder(true);
    }
  };

  const handleUpdateClientVersion = () => {
    if (noClientConditions()) {
      setNoRecorder(true);
      return;
    }

    chrome.runtime.sendMessage(
      recorderExtId,
      {
        action: "bumpClientVersion",
      },
      {},
      (response) => {
        if (response) {
          console.log("Bump version result:");
          console.log(response);
        } else {
          console.log(chrome.runtime.lastError);
        }
      },
    );
  };

  const checkVersion = async (recorderExtId: string) =>
    checkClientVersion(recorderExtId, (res: any) =>
      setUpdateClientVersion(res),
    );

  const handleRunTest = async () => {
    skipPageHints();
    if (noClientConditions()) {
      setNoRecorder(true);
      return;
    }

    const invalidClientVersion =
      (await checkVersion(recorderExtId)) !== "ignore";
    if (anyRecordRunError || invalidClientVersion)
      return setIsErrorModalOpen(true);
    const stepActions = [...testSteps.items].sort((sa) => sa.order);
    const firstAction = stepActions.shift();

    if (test || firstAction?.value) {
      const startTime = Date.now();
      let response = await chrome.runtime.sendMessage(recorderExtId, {
        action: "runTests",
        parameters: {
          url: test.baseUrl.length > 0 ? test.baseUrl : firstAction?.value,
        },
      });

      if (response && response.isDisconnected) {
        setNoConnection(true);
        return;
      }

      const options = { projectId, suiteId, testId, response, recorderExtId };
      const res = await runTest(stepActions, options);

      console.log("res: ");
      console.log(res);

      const runTime = Date.now() - startTime;
      const reports = [
        { ...res, runTime, browser: "chrome", single: true, StepId: testId },
      ];
      sendReports(reports, projectId);

      const { errorMessage } = res;
      notifications.addNotification({
        title: t("notifications:title"),
        text:
          errorMessage.length > 0
            ? t("notifications:errorMessage", {
                testName: test.name,
                error: errorMessage,
                status: t("notifications:status.error"),
                runTime: formatDuration(runTime),
                interpolation: {
                  escapeValue: false,
                },
              })
            : t("notifications:successMessage", {
                testName: test.name,
                status: t("notifications:status.success"),
                runTime: formatDuration(runTime),
                interpolation: {
                  escapeValue: false,
                },
              }),
        type: errorMessage ? "error" : "success",
      });

      reloadTest();
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setNoRecorder(false);
    setNoConnection(false);
  };

  const backPath = useSharedSteps
    ? `/projects/${test!?.ProjectId}/tests/suites/${test!?.SuiteId}/tests${
        testFilters.filterUrl
      }`
    : backTestId
    ? `/projects/${backTest!?.ProjectId}/tests/suites/${backTest!
        ?.SuiteId}/tests/${backTest!?.id}/steps`
    : `/projects/${test!?.ProjectId}/shared-steps`;

  return (
    <Box className={classes.box}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.headerContainer}
      >
        <Grid item>
          <Grid
            container
            alignItems="center"
            component={StyledButton}
            onClick={() => history.push(backPath)}
            startIcon={<ArrowBackIosOutlined />}
          >
            {useSharedSteps
              ? t("back_to_tests")
              : backTestId
              ? t("back_to_test")
              : t("back_to_shared_steps")}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item className={classes.actionItem}>
              <Grid container alignItems="center" wrap="nowrap">
                <StyledIconButton className={classes.undo}>
                  <UndoOutlined />
                </StyledIconButton>

                <StyledIconButton disabled>
                  <RedoOutlined />
                </StyledIconButton>
              </Grid>
            </Grid>
            {useSharedSteps ? (
              <Grid container ref={hintRef} className={hintAnchorClass}>
                <Grid item className={classes.actionItem}>
                  <Grid
                    container
                    alignItems="center"
                    component={StyledButton}
                    onClick={handleRecord}
                    startIcon={<FiberManualRecordOutlined />}
                  >
                    {t("record")}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    component={StyledButton}
                    onClick={handleRunTest}
                    startIcon={<PlayArrowOutlined />}
                    endIcon={<KeyboardArrowDownOutlined />}
                  >
                    {t("run_test")}
                  </Grid>
                </Grid>
                {pageHints.map((hint) => (
                  <Hint
                    key={hint.id}
                    open={showHint(hint.id)}
                    anchorEl={anchorElHint}
                    onAction={hint.action}
                    onSkip={skipAllHints}
                    title={t(`hints.${hint.id}.title`)}
                    description={t(`hints.${hint.id}.description`)}
                    actionText={t(`hints.${hint.id}.action`)}
                  />
                ))}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <UpdateClient
        isOpen={
          !["ignore", "no_response"].includes(updateClientVersion) &&
          isErrorModalOpen
        }
        onClose={handleCloseErrorModal}
        onUpdate={handleUpdateClientVersion}
        latestVersion={updateClientVersion}
      />
      <NoClient
        isOpen={noClient && isErrorModalOpen}
        onClose={handleCloseErrorModal}
      />
      <NoRecorder isOpen={noRecorder} onClose={handleCloseErrorModal} />
      <NoConnection isOpen={noConnection} onClose={handleCloseErrorModal} />
    </Box>
  );
});

export default TestHeader;
